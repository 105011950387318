import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { refreshToken } from 'app/http';
import { WebsocketsService } from 'app/API';

import { useAuthContext } from './AuthProvider';

type TProps = {
  children: ReactNode;
};

type TState = {
  onWSUpdate: number;
  userId?: string | null;
  jobId?: string | null;
  organizationId: string | null;
  transactionStatusDto: { id: string; status: 'pending' | 'success' | 'error' } | null;
  offline: boolean;
};

const WebsocketContext = createContext<TState>({
  onWSUpdate: 0,
  userId: null,
  jobId: null,
  organizationId: null,
  transactionStatusDto: null,
  offline: true,
});

interface WebsocketMessage {
  action?: 'ping';
  type: 'WSUserId' | 'WSOrganizationId' | 'WSJobId' | 'WSTransactionStatusDto';
  data: never;
}

export const WebsocketProvider = ({ children }: TProps) => {
  const { updateMe, me } = useAuthContext();
  const [onWSUpdate, setOnWSUpdate] = useState(1);
  // const [userId, setUserId] = useState<string | null | undefined>(null);
  const [jobId, setJobId] = useState<string | null | undefined>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const [transactionStatusDto, setTransactionStatusDto] = useState(null);
  const [websocketSessionId, setWebsocketSessionId] = useState<string | null>(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [offLine, setOffLine] = useState(false);
  const [pingInterval, setPingInterval] = useState<NodeJS.Timeout | string | number>();

  // TODO: fix env.d.ts to allow for import.meta.env.VITE_WEBSOCKET_HOST
  const host = (import.meta as unknown as { env: { VITE_WEBSOCKET_HOST: string } }).env.VITE_WEBSOCKET_HOST;
  const stage = (import.meta as unknown as { env: { VITE_WEBSOCKET_STAGE: string } }).env.VITE_WEBSOCKET_STAGE;
  const disabled = (
    import.meta as unknown as {
      env: { VITE_WEBSOCKET_DISABLED: string };
    }
  ).env.VITE_WEBSOCKET_DISABLED;

  const createWebsocket = () => {
    let newSocket: WebSocket = {} as WebSocket;

    try {
      newSocket = new WebSocket(`${host}/${stage}/?authToken=${websocketSessionId}`);
    } catch (err) {
      console.log('[WebsocketProvider] error on initialize', err);
    }

    newSocket.addEventListener('open', () => {
      console.log('[WebsocketProvider] open');
      setPingInterval(
        setInterval(() => {
          newSocket.send('{"type":"Ping"}');
        }, 10000),
      );
    });

    newSocket.addEventListener('message', (event) => handleWebsocketMessage(event.data));

    newSocket.addEventListener('error', function (error) {
      console.log('[WebsocketProvider] error', error);
    });

    newSocket.addEventListener('close', () => {
      if (pingInterval) {
        clearInterval(pingInterval);
      }
      if (!websocketSessionId) {
        connect();
        return;
      }
      WebsocketsService.disconnect({ xSessionId: websocketSessionId }).then(() => {
        console.log('[WebsocketProvider] socket close');
        setTimeout(() => {
          connect();
        }, 10000);
      });
    });

    return newSocket;
  };

  const handleWebsocketMessage = (message: string) => {
    const payload: WebsocketMessage = JSON.parse(message);

    if (payload.action === 'ping') {
      console.log('[ws] Received ping');
      return;
    }

    if (!payload.type) {
      return;
    }

    console.log('[ws] Message', payload.type);
    switch (payload.type) {
      case 'WSUserId': {
        updateMe();
        setOnWSUpdate(Date.now());
        break;
      }
      case 'WSOrganizationId': {
        setOrganizationId(payload.data);
        setOnWSUpdate(Date.now());
        setTimeout(() => setOrganizationId(null), 1000);
        break;
      }
      case 'WSTransactionStatusDto': {
        setTransactionStatusDto(payload.data);
        setTimeout(() => setTransactionStatusDto(null), 1000);
        break;
      }
      case 'WSJobId': {
        setJobId(payload.data);
        setOnWSUpdate(Date.now());
        setTimeout(() => setJobId(null), 1000);
        break;
      }
      default: {
        console.error('Unknown type', payload.type);
      }
    }
  };

  const connect = () => {
    if (offLine) {
      console.log('[WebsocketProvider] offline');
      return;
    }
    setWebsocketSessionId(null);
    setSocket(null);
    WebsocketsService.create()
      .then((res) => {
        if (!res.body) {
          return;
        }
        console.log('Websocket session id', res.body.sessionToken);
        setWebsocketSessionId(res.body.sessionToken);
      })
      .catch(async (e: any) => {
        if (window.location.pathname === '/account-disabled') {
          return;
        }

        console.error('[WebsocketProvider] connect error', e);
        Sentry.captureException(new Error('Unable connect to websocket'), {
          extra: {
            response: e.response,
            status: e.response?.status,
            statusText: e.response?.statusText,
            data: e.response?.data,
            user: me,
            url: window.location.href,
          },
        });

        if (e.response?.status === 401) {
          console.error('Unauthorized to connect to websocket');
          Sentry.captureException(new Error('401 exception: Unauthorized to connect to websocket'));

          await refreshToken();

          setTimeout(() => {
            connect();
          }, 30000);
        } else {
          setTimeout(() => {
            connect();
          }, 10000);
        }
      });
  };

  useEffect(() => {
    const onOnLine = () => setOffLine(false);
    const onOffLine = () => setOffLine(true);
    window.addEventListener('offline', onOffLine);
    window.addEventListener('online', onOnLine);
    return () => {
      window.removeEventListener('offline', onOffLine);
      window.removeEventListener('online', onOnLine);
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      console.log('[WebsocketProvider] disabled.');
      return;
    }
    if (!offLine) {
      console.log('[WebsocketProvider] online, connecting...');
      connect();
    }
    return () => {
      if (socket && websocketSessionId) {
        socket.close();
        WebsocketsService.disconnect({ xSessionId: websocketSessionId });
      }
    };
  }, [offLine]);

  useEffect(() => {
    if (!websocketSessionId) {
      return;
    }
    if (!socket && !disabled) {
      const newSocket = createWebsocket();
      setSocket(newSocket);
    }
  }, [websocketSessionId, socket]);

  return (
    <WebsocketContext.Provider
      value={{
        onWSUpdate,
        jobId,
        organizationId,
        transactionStatusDto,
        offline: offLine || !!disabled || !socket || !websocketSessionId,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocketContext = () => useContext(WebsocketContext);
