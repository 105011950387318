import { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Select } from '@components/form-elements/Select';
import { useAPI } from '@hooks/useAPI';
import { AdminService } from 'app/API';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 400px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
  }

  p.description {
    color: #00122d;
    margin: 30px 0;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
};

export const AdminResubmitJobModal = ({ onCancel, onSuccess, id }: TProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chosenTranscriptionModel, setChosenTranscriptionModel] = useState<string>('');
  const [priority, setPriority] = useState<string>('');
  const [dolbyDiagnosisEnabled, setDolbyDiagnosisEnabled] = useState<boolean>(false);

  const { call } = useAPI();

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Confirm</h2>
      <p className="description">Are you sure you want to resubmit job?</p>
      <div className="mb-8">
        <Select
          options={[
            { label: 'Model 1', value: '1' },
            { label: 'Model 2', value: '2' },
          ]}
          label={'Transcription model'}
          onChange={(name, value) => setChosenTranscriptionModel(value)}
          value={chosenTranscriptionModel}
        />
      </div>

      <div className="mb-8">
        <Select
          options={[
            { label: 'High', value: '1' },
            { label: 'Normal', value: '2' },
          ]}
          label={'Priority'}
          onChange={(name, value) => setPriority(value)}
          value={priority}
        />
        <small style={{ color: '#858dbd' }}>Prioritizes this job at the front of the queue for processing</small>
      </div>

      <div className="mb-8">
        <Select
          options={[
            { label: 'Enabled', value: '1' },
            { label: 'Disabled', value: '2' },
          ]}
          label={'Dolby diagnosis'}
          onChange={(name, value) => setDolbyDiagnosisEnabled(value === '1')}
          value={dolbyDiagnosisEnabled ? '1' : '2'}
        />
        <small style={{ color: '#858dbd' }}>Additional dolby diagnosis</small>
      </div>

      <form
        onSubmit={async (e) => {
          setIsLoading(true);
          e.preventDefault();
          call(
            AdminService.resubmitJob({
              id,
              forceRetranscribe: false,
              chosenTranscriptionModel,
              priority,
              dolbyDiagnosisEnabled,
            }),
          );
          setIsLoading(false);
          onSuccess();
        }}
      >
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Resubmit
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
